<template>
  <!-- Dialog de Product Page -->

  <q-dialog
    v-model="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    :maximized="$q.platform.is.mobile ? maximizedToggle : false"
    class="q-pa-md justify-center"
    v-if="product"
  >
    <!-- Card donde se encuentra el contenido principal -->

    <q-card
      :style="{ 'font-family': settings.typography }"
      class="q-pa-none q-ma-none"
    >
      <!-- Parte superior de la Card -->

      <div class="row justify-between items-center q-mt-md">
        <GoBackButton @click="deleteInfo()" />
        <div
          class="points q-mr-lg desktop-hide"
          :style="{ border: '1px solid' + settings.color_primary }"
        >
          <q-toolbar-title class="title-points">
            {{ points.points.points }} {{ settings.currency_abbreviation }}
          </q-toolbar-title>
        </div>
      </div>

      <!-- Carousel con imágenes de producto -->

      <div class="q-pa-md">
        <q-carousel
          v-model="slide"
          transition-prev="slide-right"
          transition-next="slide-left"
          arrows
          animated
          navigation
          swipeable
          height="300px"
          control-color="black"
          v-if="product.images.length > 0"
        >
          <q-carousel-slide
            v-for="(images, index) in product.images"
            :name="index"
            class=""
            :key="index"
            :img-src="
              images.url == 0
                ? publicPath + '/ow/assets/' + images.image
                : images.image
            "
            style="background-size: contain; background-repeat: no-repeat"
            loading="lazy"
          >
          </q-carousel-slide>
        </q-carousel>
        <div v-else>
          <q-img :src="defaultImageApp" />
        </div>
      </div>

      <!-- Título y puntos de producto -->

      <q-item
        class="justify-between q-ma-none items-center q-pl-lg q-pr-lg"
        style="max-width: 500px"
      >
        <h6
          class="title-products q-mt-xs q-mb-xs text-weight-bold"
          style="max-width: 180px"
        >
          {{ product.name }}
        </h6>
        <q-card-section
          class="price-products text-weight-bold"
          style="min-width: 150px"
          :style="{ color: settings.color_accent }"
        >
          {{ product.value }} {{ settings.currency_abbreviation }}
        </q-card-section>
      </q-item>

      <!-- Descripción y disponibilidad del producto -->
      <!-- Dependen de si el Dialog con las opciones se muestra o no. Si se muestra, se ocultan. -->
      <!-- Si hay Stock (que proviene del stockMessage()), el botón "Lo quiero" se muestra azul. En caso contrario, se muestra disabled-->

      <q-card-section
        class="q-ml-lg q-mr-lg q-pa-none q-ma-none"
        style="max-width: 500px"
        v-show="DialogWithOptions == false"
      >
        {{ product.description }}
      </q-card-section>
      <q-card-section
        class="q-ml-lg q-mr-lg q-mt-sm q-pa-none q-ma-none"
        style="max-width: 500px"
        id="disponibilidad-producto"
        v-show="DialogWithOptions == false"
      >
        {{ $t("BuyingProcess.products.availability") }}
        <span class="text-weight-bold">{{ stockMessage() }}</span>
      </q-card-section>

      <div class="container-button-like text-center">
        <!-- Texto de falta de puntos -->
        <!-- Se activa en la función showOptions() -->

        <div v-if="points.points.points < product.value">
          <p class="text-left text-weight-bold lackOfPoints q-mt-sm q-ma-lg">
            {{ $t("BuyingProcess.products.lack-of-points-1") }}
            {{ displayRestaOptions() }}
            {{ $t("BuyingProcess.products.lack-of-points-2") }}
          </p>
        </div>

        <!-- Botón añadir al carrito -->
        <!-- Activa la función showDialogOptions() -->


        <ButtonOptions
          @click="showDialogOptions()"
          id="buttonShowOptions"
          class="buttonOptions q-mb-md q-mt-xl"
          style="margin-top: 10vh"
          v-show="DialogWithOptions == false"
          :disabled="points.points.points < product.value || product.stock == 0"
        >
          {{ $t("BuyingProcess.products.want-button") }}
        </ButtonOptions>
      </div>

      <!-- Dialog que muestra las opciones a escoger (recoger en tienda o enviar a domicilio) -->
      <!-- :products="product.id" -->
      <div class="text-center q-mt-none q-pt-none">
        <OptionShow v-if="DialogWithOptions" :product="product" :points="points"/>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from "vue";
import { defineAsyncComponent } from "vue";
// import { mapActions } from "vuex";
import ProductPageMixin from "../mixins/ProductPageMixin";
import settings from "@/mixins/settings";
import config from "../../../../public/config.json"


const GoBackButton = defineAsyncComponent(() =>
  import("../../shared/buttons/GoBackButton.vue")
);

const ButtonOptions = defineAsyncComponent(() =>
  import("../../shared/buttons/ButtonOptions.vue")
);

const OptionShow = defineAsyncComponent(() =>
  import("../components/OptionShow.vue")
);

export default {
  mixins: [ProductPageMixin, settings],
  components: {
    GoBackButton,
    ButtonOptions,
    OptionShow,
  },
  data() {
    return {
      DialogWithOptions: false,
      lackOfPoints: false,
      aux_product: "",
      defaultImage: this.defaultImageApp,
    };
  },
  props: {
    dialog: {
      default: false,
    },
    points: {
      type: Object
    },
    product: {
      id: {
        type: Number,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      value: {
        type: Number,
        required: true,
      },
      stock: {
        type: Number,
        required: true,
      },
    },
  },
  // computed: {
  //   points() {
  //     return this.$store.state.points.points;
  //   },
  // },
  // methods: {
  //   ...mapActions("points", ["getPoints"]),
  // },
  setup() {
    return {
      dialog: ref(false),
      maximizedToggle: true,
      slide: ref(0),
      publicPath: config.api.url,
    };
  },
  mounted() {
    this.product_aux = this.product;
    // this.getPoints();
  },

  updated() {
    this.deleteInfo();
  },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

/*::-webkit-scrollbar {
  display: none;
}*/

.q-img {
  border-radius: 25px 25px 25px 0px;
  margin: 1rem;
  width: 400px;
  height: 300px;
  object-fit: contain;
}

#button-2 {
  color: white;
  font-size: 30px;
  border-radius: 50%;
}

@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    width: 560px;
    position: relative;
    left: 26%;
    top: 35px;
    min-height: 610px;
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media (max-width: 600px) {
  .q-img {
    width: 90%;
    max-height: 300px;
  }
  .q-card {
    padding-bottom: 150px;
  }
}

.q-card {
  background-color: white;
  // box-shadow: none;
  max-width: 700px;
}

.title-products {
  font-size: 16px;
  color: v-bind("settings.color_primary");
  line-height: 1.5rem;
  max-width: 240px;
}
.price-products {
  font-size: 18px;
  width: 120px;
  text-align: right;
}

@media (min-width: 600px) {
  .container-button-like {
    position: relative;
  }
}

.q-card__section--vert {
  padding: 0px;
}

.points {
  border-radius: 25px 25px 25px 0px;
  padding: 0.5rem;
  .title-points {
    color: #818181;
    font-size: 16px;
    text-transform: lowercase;
  }
}

#disponibilidad-producto {
  font-size: 12px;
}

.lackOfPoints {
  font-size: 12px;
  color: $negative2;
}
</style>
